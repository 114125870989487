import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLInputElement>{
  connect() {
    const date = new Date()
    const value = date.toISOString().split("T")[0]
    this.element.value = value
    this.element.max = value
  }
}
